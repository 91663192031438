import React from 'react';
import Helmet from 'react-helmet';
import config from '@deadline/common/data/config';

const SEO = ({ location = '', title }) => {
  const structuredDataOrganization = `{ 
				"@context": "http://schema.org",
				"@type": "Organization",
				"legalName": "Aayush Sinha",
				"url": "https://authcode.app",
				"logo": "${config.logo}",
				"founders": [{
					"@type": "Person",
					"name": "Aayush Sinha"
				}],
				"contactPoint": [{
					"@type": "ContactPoint",
					"email": "aayush.aryan@me.com",
					"telephone": "+91-7376146764",
					"contactType": "customer service"
				}],
				"address": {
					"@type": "PostalAddress",
					"addressLocality": "${
            config.address.city
          }",
					"addressRegion": "${
            config.address.region
          }",
					"addressCountry": "${
            config.address.country
          }",
					"postalCode": "${config.address.zipCode}"
				},
				"sameAs": [
					"${config.socialLinks.twitter}",
					"${config.socialLinks.google}",
					"${config.socialLinks.youtube}",
					"${config.socialLinks.linkedin}",
					"${config.socialLinks.instagram}",
					"${config.socialLinks.github}"
				]
			}`;

  return (
    <Helmet>
      <html lang="en" dir={'ltr'} />
      <meta
        name="google-site-verification"
        content={config.googleVerification}
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="AuthCode | Ultimate Authenticator App | Coming Soon"
      />
      <meta property="og:url" content="https://authcode.app" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="AuthCode"
      />
      <meta
        property="og:description"
        content="AuthCode | Ultimate Authenticator App | Coming Soon"
      />
      <meta property="og:image" content={config.cover} />
      <meta property="fb:app_id" content={config.social.facebook} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.social.twitter} />
      <meta name="twitter:site" content={config.socialLinks.twitter} />
      <meta
        name="twitter:title"
        content="AuthCode"
      />
      <meta
        name="twitter:description"
        content="AuthCode | Ultimate Authenticator App | Coming Soon"
      />
      <meta name="twitter:image:src" content={config.cover} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <link rel="publisher" href={config.socialLinks.google} />
      <title>AuthCode | Ultimate Authenticator App</title>
    </Helmet>
  );
};
export default SEO;
