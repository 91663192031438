import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialGithub } from 'react-icons-kit/ionicons/socialGithub';

export const SOCIAL_PROFILES = [
  {
    id: '1',
    icon: socialFacebook,
    url: 'https://www.facebook.com/aayush.aryan',
  },
  {
    id: '2',
    icon: socialGithub,
    url: 'https://github.com/aayusharyan',
  },
];
