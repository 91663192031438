import React from 'react';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import MainWrapper, {
  MainContentSection,
  FooterSection,
  LogoImageContainer,
  BottomShape,
  CloudImages,
  ImageContainer,
} from '@deadline/common/ui/twelve.style';
import { SOCIAL_PROFILES } from '@deadline/common/data/social-share/index';
// Language translation files
import { Container, SocialShare, SEO } from '../components';
import LogoImage from '@deadline/common/static/images/logoOne.png';
import ClouldImage1 from '@deadline/common/static/images/twelve/cloud1.svg';
import ClouldImage2 from '@deadline/common/static/images/twelve/cloud2.svg';
import ClouldImage3 from '@deadline/common/static/images/twelve/cloud3.svg';
import Shape1 from '@deadline/common/static/images/twelve/shape2.svg';
import Shape2 from '@deadline/common/static/images/twelve/shape1.svg';
import MainImage from '@deadline/common/static/images/twelve/image.png';

const IndexPage = () => (
  <ParallaxProvider>
      <React.Fragment>
        <SEO title="title" />
        <div className="fullWrapper">
          <MainWrapper>
            <Container className="mainContainer">
              <MainContentSection>
                <LogoImageContainer>
                  <Link to={'/twelve'}>
                    <img src={LogoImage} alt="logo" />
                  </Link>
                </LogoImageContainer>

                <h2>
                  Launching very soon!
                </h2>
                <p>
                  Honestly, the only developer working on this project is probably sleeping right now.
                </p>
              </MainContentSection>
              <ImageContainer>
                <img src={MainImage} alt="MainImage" />
              </ImageContainer>
            </Container>
            <CloudImages>
              <Fade left delay={400}>
                <img src={ClouldImage1} alt="CloudImage 1" />
              </Fade>
              <Fade right delay={600}>
                <img src={ClouldImage2} alt="CloudImage 2" />
              </Fade>
              <Fade top delay={800}>
                <img src={ClouldImage3} alt="CloudImage 3" />
              </Fade>
            </CloudImages>
            <div className="bottomAnimation">
              <Parallax offsetYMax={50} offsetYMin={-40}>
                <BottomShape>
                  <Fade left>
                    <img src={Shape1} alt="Bottom Shape Left" />
                  </Fade>
                  <Fade right>
                    <img src={Shape2} alt="Bottom Shape Right" />
                  </Fade>
                </BottomShape>
              </Parallax>
            </div>
            <FooterSection>
              <SocialShare items={SOCIAL_PROFILES} />
            </FooterSection>
          </MainWrapper>
        </div>
      </React.Fragment>
  </ParallaxProvider>
);

export default IndexPage;
